import { fetchUtils } from 'react-admin';
//import jsonServerProvider from 'ra-data-json-server';
import { stringify } from 'query-string';
import Backendless from 'backendless';

Backendless.serverURL = "https://eu-api.backendless.com"
Backendless.initApp("9BB41823-6DE6-8D92-FF1B-627677BD9B00", "0060C350-0BAB-4530-996E-A58CD409A524");
const apiUrl = 'https://usefulticket.backendless.app/api/data';
const node0Url = 'https://node0.motocare.ro';

const httpClient = (url: string, options: any = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    if (localStorage.getItem('auth') != null) {
        const auth: string = localStorage.getItem('auth') || "";
        const { token } = JSON.parse(auth);
        options.headers.set('user-token', `${token}`);
    }
    return fetchUtils.fetchJson(url, options);
};
//export const dataProvider = jsonServerProvider('https://usefulticket.backendless.app/api/data', httpClient);
export const dataProvider = {
    
    getList: async (resource: any, params: any) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;

        const query = {
            where: params.meta.where,
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify(params.filter),
        };

        
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

//        console.log("getlist:" + url)

        return httpClient(url).then(({ headers, json }) => {
            //console.log(json);
            return {
                //data: json,
                data: json.map((resource: { objectId: any; }) => ({ ...resource, id: resource.objectId }) ),
                total: json.length,
            };
        });
    },

    getOne: (resource: any, params: any) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
            data:  { ...json, id: json.objectId },
        })),

    getMany: async (resource: any, params: any) => {
        const query = {
            filter: JSON.stringify({ ids: params.ids }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        const { json } = await httpClient(url);
        return ({ data:  json.map((resource: { objectId: any; }) => ({ ...resource, id: resource.objectId }) ) });
    },

    getManyReference: async (resource: any, params: any) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        const { headers, json } = await httpClient(url);
        return ({
            data: json,
            total: 10,
        });
    },

    create: (resource: any, params: any) =>
        httpClient(`${apiUrl}/${resource}`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({
            data: { ...params.data, id: json.id },
        })),

    update: (resource: any, params: any) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json })),

    updateMany: async (resource: any, params: any) => {
        const query = {
            filter: JSON.stringify({ id: params.ids}),
        };
        const { json } = await httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        });
        return ({ data: json });
    },

    delete: (resource: any, params: any) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'DELETE',
        }).then(({ json }) => ({ data: json })),

    deleteMany: async (resource: any, params: any) => {
        const query = {
            filter: JSON.stringify({ id: params.ids}),
        };
        const { json } = await httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'DELETE',
            body: JSON.stringify(params.data),
        });
        return ({ data: json });
    },
    runLiveSearch: async (resource:any, params:any) => {   
        if (params.where === "") 
            return ({data : []});

        var query = {
           where: params.where,
           sortBy: params.sortBy
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        console.log(url);
        const { json } = await httpClient(url);
        return ({ data:  json.map((resource: { objectId: any; }) => ({ ...resource, id: resource.objectId }) ) });
    },
    getNode0Data: async (url:string) => {
        const { json } = await httpClient(`${node0Url}/${url}`);
        return json;
    }
};