import * as React from 'react';
import { Datagrid, List, TextField, useGetIdentity,  } from 'react-admin';

const Vehicle = () => { 

    const { identity } = useGetIdentity();
    var _where = "";
    if (identity)
        _where = `ownerId='${identity.id}'`;

    return (
        <List exporter={false} queryOptions={{ meta: { where : _where }}} >
            <Datagrid rowClick="show" optimized>            
                <TextField label="ObjectID" source="objectId" />          
                <TextField label="Name" source="bikeMake.name" />
                <TextField label="Owner" source="ownerId" />                            
            </Datagrid>
        </List>
    )
};

export default Vehicle;