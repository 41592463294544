import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Formik, Field, Form, useField, useFormikContext, FormikHelpers, useFormik } from 'formik';
import { dataProvider } from './dataProvider';
import { useEffect, useState } from 'react';
import { Autocomplete } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

interface Values {
    bikeMakeId: string;
    bikeModelId: string;
    bikeTypeId: string;
    tecdoc_modelid: string;
    tecdoc_id:string;
}

interface Motorbike {
  MCS_CAPACITY_LT: string;
  MCS_CAPACITY_TAX: string;
  MCS_CAPACITY_TECH: string;
  MCS_CONSTRUCTION_INTERVAL_END: string;
  MCS_CONSTRUCTION_INTERVAL_START: string;
  MCS_COU_ID: number;
  MCS_DRIVE_TYPE: number;
  MCS_ENGINE_TYPE: number;
  MCS_FUEL_MIXTURE: string;
  MCS_FUEL_TYPE: string;
  MCS_MTB_ID: number;
  MCS_NUMBER_OF_CYLINDERS: string;
  MCS_NUMBER_OF_VALVES: string;
  MCS_POWER_KW: number
  MCS_POWER_PS: number
  bikeMake: string;
  bikeMakeObjectId: string;
  bikeModelName: string;
  bikeModelObjectId: string;
  bikeName: string;
  bikeType: string;
  bikeTypeObjectId: string;
  driveType: string;
  engineType: string;
  fuelMix: string;
  fuelType: string;
  id: number;
  manufacturerid: number;
  modelid: number;
}



function findBikeMakes(name:string) {
    return dataProvider.runLiveSearch("BikeMake", 
    {   
        sortBy: 'name', 
        where:  `name LIKE '${name}%'`
    });
}

function findBikeModels(selectedMakeId: string, name:string) {
    return dataProvider.runLiveSearch("BikeModel", 
    {   
        sortBy: 'name', 
        where:  `BikeMake[models].objectId='${selectedMakeId}' AND tecdoc_modelid IS NOT NULL AND name LIKE '${name}%'` //
    });
}

function findBikeTypes(tecdoc_modelid: string) {
    return dataProvider.runLiveSearch("BikeType", 
    {   
        sortBy: 'description', 
        where:  `tecdoc_modelid = ${tecdoc_modelid}` //
    });
}

const MyAutoComplete = (props:any) => {
    //const [field, meta] = useField({ ...props, type: 'autocomplete' });
    const {
        values: {bikeMakeId, bikeModelId, bikeTypeId, tecdoc_modelid, tecdoc_id}, 
        setFieldValue,
      } = useFormikContext<Values>();

    const [open, setOpen] = useState(false);
    const [jsonResults, setJsonResults] = useState<any[]>([]);

    const [inputValue, setInputValue] = useState<string>('');
    const loading = open && jsonResults.length === 0;


   

    useEffect(()=> {

        let isCurrent = true;
        if (props.id === 'bikeMake' && inputValue.trim()!== '') {
            findBikeMakes(inputValue).then(res => {
                if (!!isCurrent) {                    
                    setJsonResults(res.data);
                }
            });    
        } else if (props.id === 'bikeModel' && inputValue.trim()!== '' && bikeMakeId != '') {            
            findBikeModels(bikeMakeId, inputValue).then(res => {
                if (!!isCurrent) {
                    setJsonResults(res.data);
                }
            }); 
        } else if (props.id === 'bikeType'  && inputValue.trim()!== '' && tecdoc_modelid != '') {
            findBikeTypes(tecdoc_modelid).then(res => {
                if (!!isCurrent) {
                    setJsonResults(res.data);
                }
            }); 
        }


        return () => {
            isCurrent = false;
        };

    }, [props, bikeMakeId, bikeModelId, bikeTypeId, tecdoc_modelid, tecdoc_id, setFieldValue, inputValue, loading]);   


    var nameField = props.nameField;
    return (<div>        
        <Autocomplete             
            id={props.id}
            loading={loading}
            filterOptions={(x) => x}
            onChange={(_event: any, newValue: any) => {
                //console.log("onChange",props.name, newValue.id)
                setFieldValue(props.name, newValue.id);
                if (props.name=='bikeModelId') {
                    setFieldValue("tecdoc_modelid", newValue.tecdoc_modelid);                     
                } else if (props.name=='bikeTypeId') {
                  setFieldValue("tecdoc_id", newValue.tecdoc_id); 
                }
            }}
            onInputChange={(_event, newInputValue) => {   
                setInputValue(newInputValue);
            }}
            fullWidth
            disablePortal
            options={jsonResults}
            getOptionLabel={(jsonResults) => `${jsonResults[nameField]}`}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            renderInput={(params) => <TextField {...params} label={props.label} />}
            noOptionsText={"No results"}        
            renderOption={(props, jsonResults) => (
                <Box component="li" {...props} key={jsonResults.objectId}>
                    {jsonResults[nameField]}
                </Box>
            )}        
      /></div>);
};

/*
function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
*/
const theme = createTheme();

const BikeSelector = () => {
  const [motorbike, setMotorbike] = useState<any>(
    {
        "bikeMake": "BMW",
        "bikeName": "R 1150 GS Adventure",
        "bikeType": "R21",
        "id": 104688,
        "modelid": 12742,
        "bikeModelName": "R 1150",
        "manufacturerid": 4081,
        "MCS_MTB_ID": 104688,
        "MCS_COU_ID": 255,
        "MCS_CAPACITY_TECH": "1130",
        "MCS_CAPACITY_TAX": "1150",
        "MCS_CAPACITY_LT": "113",
        "MCS_POWER_KW": 62,
        "MCS_POWER_PS": 85,
        "MCS_CONSTRUCTION_INTERVAL_START": "2002-01-01",
        "MCS_CONSTRUCTION_INTERVAL_END": "2005-12-31",
        "MCS_FUEL_TYPE": "182286088",
        "MCS_NUMBER_OF_VALVES": "4",
        "MCS_NUMBER_OF_CYLINDERS": "2",
        "MCS_ENGINE_TYPE": 8079362,
        "MCS_FUEL_MIXTURE": "97107406",
        "MCS_DRIVE_TYPE": 8282715,
        "fuelType": "benzina",
        "engineType": "motor pe benzina",
        "fuelMix": "Injectie galerie admisie/Carburator",
        "driveType": "Ax cardanic",
        "bikeMakeObjectId": "1B08EF9D-0C37-75AC-FF89-64F292B8C700",
        "bikeModelObjectId": "244AB6AC-2F8A-4215-BAC3-21B3CEAF0EAB",
        "bikeTypeObjectId": "28130428-7E60-44ED-981E-1F6EB1035C6D"
    }
);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
  
    /*console.log({
      email: data.get('email'),
      password: data.get('password'),
    });*/
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
            <TwoWheelerIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Bike Selector
          </Typography>


          <Formik 
            initialValues = {{
                bikeMakeId: '',
                bikeModelId: '',
                bikeTypeId: '',
                tecdoc_modelid: '',
                tecdoc_id: ''
              }}          
            onSubmit={(
                values: Values,
                { setSubmitting }: FormikHelpers<Values>
              ) => {
                setTimeout(() => {
                  dataProvider.getNode0Data(`getMotorbike?motorbikeid=${values.tecdoc_id}`).then((motorbike: Motorbike[])=> {
                    setMotorbike(motorbike[0]);
                    setSubmitting(false);
                  })
                }, 500);
              }}
            >
                <Form>
                    <Box sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                        
                        <Grid item xs={12}>
                            <MyAutoComplete id="bikeMake" name="bikeMakeId" nameField="name" label="Marca" />
                        </Grid>
                        
                        <Grid item xs={12}>
                            <MyAutoComplete id="bikeModel" name="bikeModelId" nameField="name" label="Model"/>
                        </Grid>
                        
                        <Grid item xs={12}>
                            <MyAutoComplete  id="bikeType" name="bikeTypeId" nameField="description" label="Tip"/>
                        </Grid>                        
                        {/*  <Grid item xs={12}>
                            <FormControlLabel
                            control={<Checkbox value="allowExtraEmails" color="primary" />}
                            label="I want to receive inspiration, marketing promotions and updates via email."
                            />
                        </Grid>*/}
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Obtine datele tehnice
                        </Button>
                        <Grid container justifyContent="flex-end">
                        <Grid item>
                            <Link href="#" variant="body2">
                              Ai cont Motocare? Logheaza-te aici
                            </Link>
                        </Grid>
                        </Grid>
                    </Box> 
                </Form>
            </Formik>
            {motorbike != null &&
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Marca
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {motorbike.bikeMake}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      Model
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {motorbike.bikeName}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>Tip</TableCell>
                    <TableCell>{motorbike.bikeType }</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>CC</TableCell>
                    <TableCell>{motorbike.MCS_CAPACITY_TECH }</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>CC taxabil</TableCell>
                    <TableCell>{motorbike.MCS_CAPACITY_TAX }</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Putere KW</TableCell>
                    <TableCell>{motorbike.MCS_POWER_KW }</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Putere Cai</TableCell>
                    <TableCell>{motorbike.MCS_POWER_PS }</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Combustibil</TableCell>
                    <TableCell>{motorbike.fuelType }</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Tip Motor</TableCell>
                    <TableCell>{motorbike.engineType }</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Alimentare</TableCell>
                    <TableCell>{motorbike.fuelMix }</TableCell>
                  </TableRow>                                            
                  <TableRow>
                    <TableCell>Transmisie</TableCell>
                    <TableCell>{motorbike.driveType }</TableCell>
                  </TableRow>  

                  <TableRow>
                    <TableCell>Interval constructie</TableCell>
                    <TableCell>{motorbike.MCS_CONSTRUCTION_INTERVAL_START }  / {motorbike.MCS_CONSTRUCTION_INTERVAL_END }</TableCell>
                  </TableRow> 
                    </TableBody>
                  </Table>
                </TableContainer>                
            }
        </Box>      
      </Container>
    </ThemeProvider>
  );
}

export default BikeSelector;