import React from 'react';
import { dataProvider } from './dataProvider';
import { authProvider } from './authProvider';
import { Admin, CustomRoutes, Login, Resource } from "react-admin";
import { Layout } from './layout'
import bikes from './bikes';
import serviceHistory from './serviceHistory';
import BikeSelector from './BikeSelector';
import { Route } from "react-router-dom";
import LoginAside from './layout/LoginAside';

//import { Route } from "react-router-dom";

const App = () => {

  return (<Admin
    dataProvider={dataProvider} 
    authProvider={authProvider} 
    layout={Layout}
    loginPage={LoginAside}
    disableTelemetry
    requireAuth
  > 

    <Resource name="Vehicle" {...bikes}/>
    <Resource name="Service" {...serviceHistory}/>
    <CustomRoutes noLayout>
      <Route path="/bikeselector" element={<BikeSelector />} />
    </CustomRoutes>
  </Admin>)
};

export default App;