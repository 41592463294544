/* eslint-disable import/no-anonymous-default-export */
import * as React from 'react';
import { Layout, LayoutProps } from 'react-admin';
//import AppBar from './AppBar';
import Menu from './Menu';
import { ReactQueryDevtools } from 'react-query/devtools'
// eslint-disable-next-line react/display-name 
//appBar={AppBar} menu={Menu}

export default (props: LayoutProps) => {
    return (<>
        <Layout {...props} menu={Menu} />
        <ReactQueryDevtools />
        </>);
};
