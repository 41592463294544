import * as React from 'react';
import { Datagrid, List, Loading, TextField, useGetIdentity, useGetOne,  } from 'react-admin';

//import Backendless from 'backendless';

const Service = () => { 

    const { identity } = useGetIdentity();



    const { data, isLoading, error } = useGetOne('Vehicle', { id : "97C1D979-A045-45BA-ABFF-3498E694E106"});
    if (isLoading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }
 
   
    console.log(data);

    /*
    var myBike = new Classes.Vehicle({objectId: "97C1D979-A045-45BA-ABFF-3498E694E106"});
    myBike.getVehicle();
    console.log(myBike);
    */


    //var bikeService = myVehicle.getServices();
    //console.log(bikeService);

    /*var queryBuilder = Backendless.DataQueryBuilder.create();
    queryBuilder.setRelated( [ "RELATED-PROPERTY-NAME", 
                           "RELATED-PROPERTY-NAME.RELATION-OF-RELATION" ] );

    Backendless.Data.of( Classes.Vehicle ).find( queryBuilder )
    .then( function( objectCollection ) {
    })
    .catch( function( error ) {
    });
    */


        

    /*
    StringBuilder whereClause = new StringBuilder();
            whereClause.append( "Vehicle[services]" );
            whereClause.append( ".objectId='" ).append( objectId ).append( "'" );
    */


    return (
        <List exporter={false} queryOptions={{ meta: { where : `Vehicle[services].objectId='97C1D979-A045-45BA-ABFF-3498E694E106'` }}} >
            <Datagrid rowClick="show" optimized>            
                <TextField label="ObjectID" source="objectId" />          
                <TextField label="ServiceDate" source="ServiceDate" />
                <TextField label="Cost" source="Cost" />                            
            </Datagrid>
        </List>
    )
};

export default Service;