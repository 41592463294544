import * as React from "react";
import { Show, SimpleShowLayout, TextField, DateField, RichTextField, WithRecord } from 'react-admin';
import { Avatar } from '@mui/material';
import LiveSearch from "../components/LiveSearch";

const MyBikeImage = () => {
    return (<WithRecord render={record => 
        <Avatar alt={record.bikeMake.name} src={record.imageUrl} sx={{ width: 120, height: 120 }}/>
    }/>);
}

const BikeDetails = () => {
    return (<Show emptyWhileLoading>
        <SimpleShowLayout>
           
            <MyBikeImage/>
            <TextField source="bikeMake.name" />
            <TextField source="bikeModel.name" />
            <TextField source="VIN" />
            <TextField source="currentKM" />
            <RichTextField source="kmAtPurchase" />
            <DateField label="" source="policyEndDate" />
        </SimpleShowLayout>
    </Show>)
};

export default BikeDetails;