import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import {
    useTranslate,
    MenuItemLink,
    MenuProps,
    useSidebarState,

} from 'react-admin';


import BookIcon from '@mui/icons-material/Book';


type MenuName = 'menuCatalog' | 'menuSales' | 'menuCustomers' | 'menuSupport';

const Menu = ({ dense = false }: MenuProps) => {
    const [state, setState] = useState({
        menuCatalog: true,
        menuSales: false,
        menuCustomers: false,
        menuSupport: true
    });
    const translate = useTranslate();
    const [open] = useSidebarState();

    /*
    const handleToggle = (menu: MenuName) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };
    */
    
    return( 
    <Box
        sx={{flex: 1,
            height: '100%',
            width: open ? 200 : 50,
            marginTop: 1,
            marginBottom: 1,
            transition: theme =>
                theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
        }}
    >      
        <MenuItemLink
            to="/Vehicle"
            state={{ _scrollToTop: true }}
            primaryText="My Bikes"            
            leftIcon={<BookIcon />}
            dense={dense}
        />  

        <MenuItemLink
            to="/Service"
            state={{ _scrollToTop: true }}
            primaryText="Service History"            
            leftIcon={<BookIcon />}
            dense={dense}
        />  


    </Box>
    )
};

export default Menu;